<template>
  <div class="container-fluid py-5">
    <div class="offset-md-2 col-md-8">
      <form v-if="!sent" @submit.prevent="sendSubscription" id="registration" class="row g-2 justify-content-center" :class="{'disabled': disabled}">
        <div class="col-5">
          <input class="textfield" ref="nome" required="true" placeholder="nome" name="nome" />
        </div>
        <div class="col-5">
          <input class="textfield" ref="cognome" required="true" placeholder="cognome" name="cognome" />
        </div>
        <div class="col-5">
          <input class="textfield" ref="eta" required="true" placeholder="età" name="eta" type="number" min="16" max="40" />
        </div>
        <div class="col-5">
          <input class="textfield" ref="mail" required="true" placeholder="mail" name="mail" type="email" />
        </div>
        <div class="col-5">
          <input class="textfield" ref="telefono" required="true" placeholder="telefono" name="telefono" type="tel" />
        </div>
        <div class="col-5">
          <select ref="tipologia" required="true" name="tipologia">
            <option value="">- tipologia -</option>
            <option value="gruppo">gruppo</option>
            <option value="solista">solista</option>
          </select>
        </div>
        <div class="col-10">
          <textarea ref="strumenti" required="true" placeholder="strumenti"></textarea>
        </div>
        <div class="col-10 song">
          <p>Per poterti registrare, ti chiediamo gentilmente di caricare il tuo brano su una piattaforma idonea, come <a href="https://wetransfer.com/" target="_blank">Wetransfer</a>, <a href="https://drive.google.com/" target="_blank">Google Drive</a>, <a href="https://youtube.com/" target="_blank">Youtube</a> o altre opzioni che preferisci. Una volta caricato, copia il link diretto al tuo brano e incollalo qui di seguito. Assicurati che il link sia accessibile e che il brano possa essere consultato senza restrizioni.</p>
          <p>Ricorda che la tua esibizione sarà valutata in base al link fornito, quindi assicurati che sia corretto e funzionante.</p>
          <input class="textfield" ref="brano" required="true" placeholder="link al brano" name="brano" type="url" />
          <p>In caso di problemi o dubbi, non esitare a contattarci. Puoi inviare una mail a <a href="mailto:info@notepoconote.it">info@notepoconote.it</a> oppure scrivere su Whatsapp al numero <a href="https://wa.me/+393386842331">+39 338.68.42.331</a>.</p>
          <!--<input type="file" accept=".wav,.mp3" required="true" ref="file">-->
        </div>
        <div class="col-10">
          <div>
            <input type="checkbox" value="true" required> Ho letto e accetto <a href="/notepoconote_2024_regolamento.pdf" download>il regolamento</a>.
          </div>
          <div>
            <input type="checkbox" value="true" required> Ho letto e accetto <a href="/notepoconote_2024_privacypolicy.pdf" download>l'informativa sulla privacy</a>.
          </div>
        </div>
        <div class="col-10">
          <button class="btn btn-secondary px-5" type="submit" :disabled="disabled">INVIA</button>
        </div>
      </form>
      <div v-else>
        Registrazione inviata!
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleSpreadsheet } from 'google-spreadsheet'
import { JWT } from 'google-auth-library'
import creds from '@/config/contestmusicale-256e420dd092.json'
//import axios from 'axios'

const FILE = '1kMdbuRhGmyd6sC8QK7OU3t_9ApQtwmyM6G8N8Snt9lc'
const SHEET = 0
const SCOPES = ['https://www.googleapis.com/auth/spreadsheets', 'https://www.googleapis.com/auth/drive']

export default {
  name: 'SubmissionForm',
  data() {
    return {
      disabled: false,
      sent: false
    }
  },
  methods: {
    validateParams: async function () {
      let params = this.params.filter(el => el == '' || el == null || el == undefined)
      if (params.length > 0) {
        return false
      }
      return true
    },
    sendSubscription: async function() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      if (!token) {
        return ''
      }

      let self = this
      this.disabled = true

      this.params = [
        this.$refs.nome.value,
        this.$refs.cognome.value,
        this.$refs.eta.value,
        this.$refs.mail.value,
        this.$refs.telefono.value,
        this.$refs.tipologia.value,
        this.$refs.strumenti.value,
        this.$refs.brano.value
      ]

      if (await this.validateParams()) {
        const serviceAccountAuth = new JWT({
          email: creds.client_email,
          key: creds.private_key,
          scopes: SCOPES
        });
        
        const doc = new GoogleSpreadsheet(FILE, serviceAccountAuth);
        await doc.loadInfo()
        const sheet = doc.sheetsByIndex[SHEET]
        /*
        this.song = this.$refs.file.files[0];
        let song_ext = this.song.name.split('.').pop()
        let song_name = this.$refs.nome.value + ' ' + this.$refs.cognome.value + '.' + song_ext
        */
        await sheet.addRow({
          Nome: this.$refs.nome.value,
          Cognome: this.$refs.cognome.value,
          Eta: this.$refs.eta.value,
          Email: this.$refs.mail.value,
          Telefono: this.$refs.telefono.value,
          Tipologia: this.$refs.tipologia.value,
          Strumenti: this.$refs.strumenti.value,
          Brano: this.$refs.brano.value
        });

        this.disabled = false
        self.sent = true
        /*
        const formData = new FormData();
        formData.append("file", this.song);
        formData.append('metadata', new Blob([JSON.stringify({ name: song_name, parents: ['1oZYqB1F2sMaYsQd_5rkebYrporq3aJUF'] })], { type: 'application/json' }));
        axios
          .post("https://www.googleapis.com/upload/drive/v3/files", formData, {
            headers: { Authorization: `Bearer ${serviceAccountAuth.credentials.access_token}` },
          })
          .then(() => {
            self.sent = true
          })
          .catch(() => {
            self.disabled = false
          })
        */
      }
      else {
        this.disabled = false
        self.sent = true
      }
    }
  }
}
</script>

<style lang="scss">
#registration {
  .textfield, select, textarea {
    width: 100%;
    max-width: 100%;
    border: 1px solid #69727d;
    background-color: transparent;
    color: #1f2124;
    vertical-align: middle;
    flex-grow: 1;

    font-size: 16px;
    min-height: 47px;
    padding: 6px 16px;
    border-radius: 4px;
  }
  textarea {
    width: 100%;
  }
  .btn {
    background: #F59000;
    border-color: #F59000;
  }
  &.disabled {
    opacity: .5;
  }
  .song {
    p {
      font-size: .9em;
    }
  }
}
</style>