<template>
  <SectionHeader />
  <SectionContest />
  <SectionEvent />
  <SectionSubscription />
  <SectionSponsor />
  <SectionContacts />
</template>

<script>
import SectionHeader from '@/sections/SectionHeader.vue'
import SectionSubscription from './sections/SectionSubscription.vue'
import SectionContest from '@/sections/SectionContest.vue'
import SectionEvent from '@/sections/SectionEvent.vue'
import SectionSponsor from '@/sections/SectionSponsor.vue'
import SectionContacts from '@/sections/SectionContacts.vue'

export default {
  name: 'App',
  components: {
    SectionSubscription,
    SectionHeader,
    SectionContest,
    SectionSponsor,
    SectionContacts,
    SectionEvent
  }
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.btn-primary {
  background: #000;
  border: 1px solid #000;
  color: #F48D00;
}
</style>
