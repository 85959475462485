<template>
  <div id="section-contacts" class="container-fluid py-5">
    <h2>Contattaci!</h2>
    <div class="row">
      <div class="col">
        <div><a href="tel:+39 338 684 2331">Tel. +39 338 684 2331</a></div>
        <div><a href="mailto:info@notepoconote.it">Email. info@notepoconote.it</a></div>
        <div class="social">
          <!-- <a href=""><i class="bi bi-facebook"></i></a>
          <a href=""><i class="bi bi-instagram"></i></a> -->
          <a href="https://wa.me/+393386842331"><i class="bi bi-whatsapp"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#section-contacts {
  background: #000;
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .social {
    font-size: 1.5rem;
    margin-top: 20px;
    a {
      padding: 10px;
    }
  }
}
h2 {
        color: white;
        font-family: 'Alfa Slab One', serif;
        font-size: 3.5rem;
        @media (min-width: 768px) {
            font-size: 5rem;
        }
    }
</style>