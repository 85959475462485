<template>
    <header>
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid">
                <button @click.stop="toggleNavbar = !toggleNavbar" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse" :class="{'collapse': toggleNavbar == false}" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                    <a @click.stop="toggleNavbar = !toggleNavbar" class="nav-link" aria-current="page" href="#section-contest">Contest</a>
                    </li>
                    <li class="nav-item">
                    <a @click.stop="toggleNavbar = !toggleNavbar" class="nav-link" href="#section-contacts">Contatti</a>
                    </li>
                </ul>
                <a @click.stop="toggleNavbar = !toggleNavbar" class="btn btn-primary  btn-lg" type="submit" href="#section-subscription">ISCRIVITI</a>
                </div>
            </div>
        </nav>
        <div class="cover" style="background-image:url('/img/header.jpeg')">
          <img src="/img/header.jpeg">
        </div>
    </header>
</template>

<script>
export default {
  name: 'SectionHeader',
  data() {
    return {
        toggleNavbar: false
    }
  }
}
</script>

<style lang="scss">
header {
    background: #F59000;
    font-family: 'Alfa Slab One', serif;
    @media (min-width: 450px) {
    height: calc(100vh - 300px);
    }
    overflow: hidden;
    .cover {
        @media (min-width: 450px) {
            background: center bottom no-repeat;
            background-size: auto 100%;
            height: 100%;
            margin-top: 24px;
        }
        @media (min-width: 992px) {
            margin-top: -10px;
        }
        img {
            display: block;
            height: auto;
            margin-top: 24px;
            width: 100%;
            @media (min-width: 450px) {
                display: none;
            }
        }
    }
    img {
        height:100%
    }
    .navbar {
        background: #F59000!important;
        .navbar-brand {
            font-family: 'Alfa Slab One', serif;
            img {
                display: inline-block;
                height: 100px;
            }
        }
        a {
            color: #000;
        }
    }
    .btn-primary {
        color: #F59000!important;
        &:hover {
            background-color: #fff;
            border-color: #fff;
            color: #F59000;
        }
    }
    .navbar {
        position: fixed;
        top: 0;
        width: 100%;
        @media (min-width: 992px) {
            position: static;
        }
    }
}
</style>