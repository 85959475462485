<template>
  <div id="section-subscription" class="container-fluid py-5">
    <h2>Iscriviti!</h2>
    <p>Compila il form con i tuoi dati e allega il brano che vorresti suonare.<br>Verrai ricontattato dal nostro staff.</p>
    <SubscriptionForm></SubscriptionForm>
  </div>
</template>

<script>
import SubscriptionForm from '@/components/SubscriptionForm.vue'


export default {
  name: 'HomePage',
  components: {
    SubscriptionForm
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#section-subscription {
  background: #ececec;
}
 h2 {
      color: black;
      font-family: 'Alfa Slab One', serif;
      font-size: 4rem;
  }
</style>
