<template>
    <div class="container-fluid contest py-5">
        <h2>1° Edizione</h2>
        <h3>22.06.2024</h3>
    </div>
</template>

<style lang="scss">
.contest {
    background: #000;
    h2 {
        color: white;
        font-family: 'Alfa Slab One', serif;
        font-size: 3.5rem;
        @media (min-width: 768px) {
            font-size: 5rem;
        }
    }
    h3 {
        color: #F59000;
        font-family: 'Alfa Slab One', serif;
        font-size: 3rem;
    }
}
</style>