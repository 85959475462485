<template>
    <div id="section-contest" class="container-fluid event py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <h4>Una vetrina per giovani talenti musicali</h4>
                    <p>NOTE poco NOTE è una manifestazione musicale rivolta a giovani talenti di qualsiasi genere e stile musicale. L'evento, organizzato dalle associazioni "Sui Sentieri Della Musica" e "Andrea Simeone", vuole offrire una vetrina a giovani artisti emergenti, dando loro l'opportunità di esibirsi dal vivo davanti a un pubblico di appassionati.</p>
                    <p>La partecipazione al concorso è aperta a band, voci e strumenti solisti, gruppi jazz o di musica classica. I concorrenti dovranno avere un'età compresa tra i 14 e i 40 anni compiuti (i concorrenti minorenni dovranno essere accompagnati da un genitore).</p>

                    <h4>La selezione dei partecipanti avverrà in due fasi:</h4>
                    <p>Fase di pre-selezione: i concorrenti dovranno inviare un brano demo da loro composto. I migliori 10 solisti e 10 band/gruppi accederanno alla fase finale.</p>
                    <p>Fase finale: i 20 selezionati si esibiranno dal vivo presso il Parco Pratone di Venegono Superiore. I migliori 5 accederanno alla fase finale, in cui dovranno esibirsi in un brano da loro composto diverso da quello utilizzato per la fase di pre-selezione.</p>
                    
                    <h4>I premi in palio per i primi tre classificati sono:</h4>
                    <ul>
                        <li>Esibizione in diretta presso l'auditorium di Radio Popolare di Milano</li>
                        <li>Passaggio nelle conduzioni musicali di Radio Popolare</li>
                        <li>Registrazione professionale/digitale di un master</li>
                    </ul>
                    <div class="text-center">
                        <a class="btn btn-primary" href="notepoconote_2024_regolamento.pdf" download>Scarica il Regolamento</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.event {
    background: white;
    text-align: left;
    .btn {
        color: #F59000;
        font-family: 'Alfa Slab One', serif;
    }
}
</style>