<template>
  <div class="container sponsor py-5">
    <ul>
      <li><img src="/img/sponsor/sentieri.jpg" /></li>
      <li><img src="/img/sponsor/cielo.jpg" /></li>
      <li><img src="/img/sponsor/radio.jpg" /></li>
      <li><img src="/img/sponsor/village.jpg" /></li>
      <li><img src="/img/sponsor/proloco.jpg" /></li>
      <li><img src="/img/sponsor/alpini.jpg" /></li>
      <li><img src="/img/sponsor/protezione.jpg" /></li>
      <li><img src="/img/sponsor/comune.jpg" /></li>
    </ul>
  </div>
</template>

<style lang="scss">
.sponsor {
  background: #fff;
  ul {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      flex: 1;
      padding: 20px 5px;
      margin: 0;
      img {
        width: 100%;
      }
      @media (min-width: 768px) {
        padding: 20px 5px;
      }
      @media (min-width: 992px) {
        padding: 20px 20px;
      }
      @media (min-width: 1200px) {
        padding: 20px 40px;
      }
    }
  }
  h2 {
        color: white;
        font-family: 'Alfa Slab One', serif;
        font-size: 3.5rem;
        @media (min-width: 768px) {
            font-size: 5rem;
        }
    }
}
</style>